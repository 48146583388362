export enum ExternalURL {
  discord,
  twitter,
  notion,
  discourse,
}

export const externalURL = (externalURL: ExternalURL) => {
  switch (externalURL) {
    case ExternalURL.discord:
      return 'http://discord.gg/soversdao';
    case ExternalURL.twitter:
      return 'https://twitter.com/soversdao';
    case ExternalURL.notion:
      return 'https://sovers.notion.site/';
    case ExternalURL.discourse:
      return 'https://discourse.sovers.wtf/';
  }
};
