import Section from '../../layout/Section';
import { Col } from 'react-bootstrap';
import classes from './Documentation.module.css';
import Accordion from 'react-bootstrap/Accordion';
import Link from '../Link';

const Documentation = () => {
  const cryptopunksLink = (
    <Link text="Cryptopunks" url="https://www.larvalabs.com/cryptopunks" leavesPage={true} />
  );
  const playgroundLink = <Link text="sovers playground" url="/playground" leavesPage={false} />;
  const publicDomainLink = (
    <Link
      text="public domain"
      url="https://creativecommons.org/publicdomain/zero/1.0/"
      leavesPage={true}
    />
  );
  const compoundGovLink = (
    <Link text="Compound Governance" url="https://compound.finance/governance" leavesPage={true} />
  );
  return (
    <Section fullWidth={false}>
      <Col lg={{ span: 10, offset: 1 }}>
        <div className={classes.headerWrapper}>
          <h1>WTF?</h1>
          <p>
          Sovers are an experimental attempt to improve the formation of on-chain avatar communities aiming at long term prosperity through passive income. While projects such as {cryptopunksLink} have attempted to bootstrap digital community and identity, and Nouns have aimed at identity, community, and governance, Sovers merge all these elements, adding an innovative component of financial freedom driven by its community.
          </p>
          <p>
            Learn more about on-chain sovers below, or make some off-chain sovers using{' '}
            {playgroundLink}.
          </p>
        </div>
        <Accordion flush>
          <Accordion.Item eventKey="0" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>Summary</Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>sovers artwork is {publicDomainLink}</li>
                <li>1 sover trustlessly auctioned every 24 hours, forever</li>
                <li>settlement of one auction kicks off the next</li>
                <li>100% of sover auction proceeds (“Principal”) are trustlessly sent to Sovers DAO treasury (“Sovers Pool”).</li>
                <li>principal cannot be redeemed and is automatically invested in DeFi protocols generating yield/returns/APY/earnings (“Profits”) to Sovers. </li>
                <li>That is, return on Treasury investments will automatically generate monthly PASSIVE INCOME to every Sover, FOREVER.</li>
                <li>Profits can be redeemed by all Sovers on a monthly basis.</li>
                <li>All Sovers are members of Sovers DAO</li>
                <li>Sovers DAO uses a fork of Nouns DAO, a derivation of the {compoundGovLink} protocol </li>
                <li>1 Sover = 1 vote</li>
                <li>Treasury is controlled exclusively by Sovers via governance Sovers can propose, vote and choose to allocate up to 20% of the Profits to be used by DAO Guilds</li>
                <li>
                Artwork is generative and stored directly on-chain (not IPFS)
                </li>
                <li>No explicit rules for attribute scarcity, all Sovers are equally rare</li>
                <li>'Soverers' receive rewards in the form of Sovers (10% of supply for first 5 years) and fees to support further development (10% of Sovers’ pool profits)</li>
                <li>To incentivize Non-Sovers (“Outsiders”) to use the protocol, 30% of the Sovers Pool’s profits will be distributed to Outsiders. 
                </li>
                <li>
                Outsiders will be able to invest their capital in a segregated investment pool (“Outsiders Pool”), and profit from generated yield/return/APY applying the same investment strategies as the Sovers Pool. Outsiders pay a success fee of 10% on generated profits. 80% of these fees’ proceeds are directed to Sovers Pool in order to indefinitely increase Sovers’ PERPETUAL INCOME. Therefore, the more Outsiders use the protocol, the larger will be the Sovers’ monthly income. 
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>Daily Auctions</Accordion.Header>
            <Accordion.Body>
              <p>
                The Sovers Auction Contract will act as a self-sufficient sover generation and
                distribution mechanism, auctioning one sover every 24 hours, forever. 100% of auction
                proceeds (ETH) are automatically deposited in the Sovers DAO treasury, where they are
                governed by sover owners.
              </p>

              <p>
                Each time an auction is settled, the settlement transaction will also cause a new
                sover to be minted and a new 24 hour auction to begin.{' '}
              </p>
              <p>
                While settlement is most heavily incentivized for the winning bidder, it can be
                triggered by anyone, allowing the system to trustlessly auction sovers as long as
                Ethereum is operational and there are interested bidders.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>Sovers DAO</Accordion.Header>
            <Accordion.Body>
              <p>
              Sovers DAO utilizes a fork of the Noun DAO, a derivation of the Compound Governance protocol, and is the main governing body of the Sovers ecosystem. The Sovers Pool receives 100% of proceeds from daily sovers auctions. Each sover is an irrevocable member of Sovers DAO and entitled to one vote in all governance matters. Sover votes are non-transferable (if you sell your noun the vote goes with it) but delegatable, which means you can assign your vote to someone else as long as you own your Sover.
              </p>
              <p>
              Sovers can propose, vote and choose to allocate up to 20% of the Sovers Pool’s monthly Profits to be controlled and used by DAO Guilds. The primary objectives of Sovers DAO is to spread Sovers purpose to the world, enhance the protocol’s investment strategies and onboard as many Outsiders as possible to exponentially increase Sovers’ PERPETUAL INCOME. 
              </p>
              <p>
              The main objective of the NFT sales proceeds (aka Principal), is to generate PERPETUAL INCOME to Sovers. However, in extraordinary events, the Principal could be redeemed if: i) a proposal to do so is created and the vote counts on a minimum quorum of 70% of the existing Sovers; ii) the proposal is approved by at least 90% of the votes; and iii) the Soverers do not veto the result  
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              Governance
            </Accordion.Header>
            <Accordion.Body>
              <p>
                In addition to the precautions taken by Compound Governance, Soverers have given themselves a special veto right to ensure that no malicious proposals can be passed while the sover supply is low. This veto right will only be used if an obviously harmful governance proposal has been passed, and is intended as a last resort.
              </p>
              <p>
                Soverers will proveably revoke this veto right when they deem it safe to do so. This decision will be based on a healthy Sover distribution and a community that is engaged in the governance process.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>Sover Traits</Accordion.Header>
            <Accordion.Body>
              <p>
                Sovers are generated randomly based on Ethereum block hashes. There are no 'if' statements or other rules governing Sover trait scarcity, which makes all Sovers equally rare. As of this writing, Sovers are made up of:
              </p>
              <ul>
                <li>backgrounds (2) </li>
                <li>bodies (30)</li>
                <li>heads (12) </li>
                <li>faces(234) </li>
                <li>hair+accessories (128)</li>
              </ul>
              You can experiment with off-chain sover generation at the {playgroundLink}.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              On-Chain Artwork
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Sovers are stored directly on Ethereum and do not utilize pointers to other networks
                such as IPFS. This is possible because sover parts are compressed and stored on-chain
                using a custom run-length encoding (RLE), which is a form of lossless compression.
              </p>

              <p>
                The compressed parts are efficiently converted into a single base64 encoded SVG
                image on-chain. To accomplish this, each part is decoded into an intermediate format
                before being converted into a series of SVG rects using batched, on-chain string
                concatenation. Once the entire SVG has been generated, it is base64 encoded.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              Sover Seeder Contract
            </Accordion.Header>
            <Accordion.Body>
              <p>
                The Sover Seeder contract is used to determine Sover traits during the minting
                process. The seeder contract can be replaced to allow for future trait generation
                algorithm upgrades. Additionally, it can be locked by the Sovers DAO to prevent any
                future updates. Currently, Sover traits are determined using pseudo-random number
                generation:
              </p>
              <code>keccak256(abi.encodePacked(blockhash(block.number - 1), soverId))</code>
              <br />
              <br />
              <p>
                Trait generation is not truly random. Traits can be predicted when minting a Sover on
                the pending block.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              Soverer's Reward
            </Accordion.Header>
            <Accordion.Body>
              <p>
                'Soverers' are the group of ten builders that initiated Sovers. Here are the
                Soverers:
              </p>
              <ul>
                <li>
                  <Link text="@0xSover" url="https://twitter.com/0xSover" leavesPage={true} />
                </li>
                <li>
                  <Link text="@TropicoolArtz" url="https://twitter.com/TropicoolArtz" leavesPage={true} />
                </li>
                <li>
                  <Link text="@artsovers" url="https://twitter.com/artsovers" leavesPage={true} />
                </li>
              </ul>
              <p>
                Because 100% of sover auction proceeds are sent to Sovers DAO, Soverers have chosen to
                compensate themselves with sovers. Every 10th sover for the first 5 years of the
                project (sover ids #0, #10, #20, #30 and so on) will be automatically sent to the
                Soverer's multisig to be vested and shared among the founding members of the
                project.
              </p>
              <p>
                Soverer distributions don't interfere with the cadence of 24 hour auctions. Sovers
                are sent directly to the Soverer's Multisig, and auctions continue on schedule with
                the next available sover ID.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Section>
  );
};
export default Documentation;
